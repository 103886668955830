<script>
import mixpanel from "mixpanel-browser/src/loader-module";

export default {
  data() {
    return {};
  },
  methods: {
    handleClick(text) {
      console.log('click')
      mixpanel.track(text)
      window.Kakao.Channel.chat({ channelPublicId: '_Taxexmxj',});
    }
  }
};
</script>

<template>
  <div>
    <!-- Features start -->
    <section class="section bg-light" id="caution">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">도배 전 꼭 확인하세요</h2>
            <p class="text-muted">
            </p>
          </div>
        </div>

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5">
            <h2 class="mb-4">벽지 종류</h2>
            <p class="text-muted mb-5">
              1. 합지 : 종이 소재의 벽지라고 생각하시면 됩니다.
              <br />
              자가보다는 전셋집이나, 월세집에 만이 시공하는 경우가 많습니다.
              <br />
              2. 실크벽지 : 속지는 종이, 겉은 PVC재질(플라스틱 같은) 2겹으로 되어 있습니다.
              <br />
              낙서나 이물질, 액체 등이 튀었을 때 쉽게 지울 수 있다는 점과 내구성이 좋다는 장점이 있습니다.
            </p>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img
                    src="@/assets/images/paper.png"
                    alt=""
                    class="img-fluid ms-auto rounded shadow"
                />
              </div>
              <img
                  src="@/assets/images/dot-img.png"
                  alt=""
                  class="dot-img-left"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <div class="row align-items-center section pb-0">
          <div class="col-md-6">
            <div class="position-relative mb-md-0 mb-5">
              <div class="me-5 features-img">
                <img
                    src="@/assets/images/businessperson.jpg"
                    alt=""
                    class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                  src="@/assets/images/dot-img.png"
                  alt=""
                  class="dot-img-right"
              />
            </div>
          </div>
          <div class="col-md-5 ms-md-auto">
            <h2 class="mb-4">
              주의 사항
            </h2>
            <p class="text-muted mb-5">
              1. 거주중에 도배하려면 짐을 정리해 주셔야 합니다.
              <br />
              2. 벽 상태가 좋지 않은 경우 기존 벽지를 제거하지 않는 상황도 있습니다.
              <br />
              3. 곰팡이 문제는 도배로 해결되지 않을 수 있습니다.
            </p>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Features end -->

    <section class="section bg-gradient-primary">
      <div
          class="bg-overlay-img"
          :style="{
          'background-image':
            'url(' + require('@/assets/images/demos.png') + ')',
        }"
      ></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="text-center">
              <h2 class="text-white mb-4">집수리의 모든 문제를 <br />고쳐줘가 해결해 드립니다.</h2>
              <p class="text-white mb-5 font-size-16">
                업체 찾기, 수리 요청, 사전 견적 비교에 대한
                <br />
                모든 문제를 고쳐줘를 통해 해결하세요.
                <br />
                간단한 요청으로 충분합니다.
              </p>
              <a href="#" @click="handleClick('고쳐줘에 요청하기')" class="btn btn-lg btn-light">고쳐줘에 요청하기</a>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Cta end -->
  </div>
</template>