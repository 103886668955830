<script>
import { SearchIcon, ShieldIcon, HeartIcon } from "vue-feather-icons";

export default {
  components: { SearchIcon, ShieldIcon, HeartIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <!-- Services start -->
  <section class="section" id="services">
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-lg-8 text-center">
          <h2 class="fw-bold">서비스 안내</h2>
          <p class="text-muted">
            저희는 편리한 업체 찾기, 신뢰할 수 있는 가격 정보,
            <br/>
            A/S 적극 관리를 통해 고객님들의 문제를 해결하고 있습니다.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <search-icon class=""></search-icon>
              </div>
              <h4 class="mb-3 font-size-22">편리한 업체 찾기</h4>
              <p class="text-muted mb-0">
                사진과 내용만 입력하시면
                <br />
                근처의 수리업체를 찾아드립니다.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div
            class="service-box text-center px-4 py-5 position-relative mb-4 active"
          >
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <shield-icon class=""></shield-icon>
              </div>
              <h4 class="mb-3 font-size-22">가격 정보, 신뢰</h4>
              <p class="text-muted mb-0">
                업체 정보 확인을 통해
                <br />
                믿을만한 업체의 견적을 알려드립니다.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <heart-icon class=""></heart-icon>
              </div>
              <h4 class="mb-3 font-size-22">A/S 적극 관리</h4>
              <p class="text-muted mb-0">
                시공 후 일어날 수 있는
                <br />
                문제를 관리 해 드립니다.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Services end -->
</template>