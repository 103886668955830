<script>
import { SunriseIcon, ArrowRightIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Services from "@/components/papering/services";
import Usages from "@/components/papering/usages";
import Contact from "@/components/papering/contact";
import Footer from "@/components/papering/footer";
import Testimonial from "@/components/papering/testimonial";
import mixpanel from "mixpanel-browser/src/loader-module";
import Procedure from "@/components/papering/procedure";
import Caution from "@/components/papering/caution";

export default {
  components: {
    Caution,
    Procedure,
    Testimonial,
    SunriseIcon,
    ArrowRightIcon,
    Navbar,
    Services,
    Usages,
    Contact,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      console.log('click')
      mixpanel.track('지금 바로 업체찾기')
      window.Kakao.Channel.chat({ channelPublicId: '_Taxexmxj',});
    }
  },
};
</script>

<template>
  <div>
    <Navbar :navcolor="'light'" />
    <div v-b-scrollspy>
      <!-- Hero Start -->

      <!-- Hero Start -->
      <section
        class="hero-6 bg-center position-relative overflow-hidden"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/hero-6-bg.png') + ')',
        }"
        id="home"
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <sunrise-icon
                class="mb-4 icon-lg sw-1_5 text-primary"
              ></sunrise-icon>
              <h1 class="font-weight-semibold mb-4 hero-6-title">
                근처 도배업체, <br/>
                일일이 견적받기  <br/>
                어려우셨죠?
              </h1>
              <p class="mb-5 text-muted">
                요청 시 업체들의
                <br/>
                사전견적, 정보를 알려드립니다.
                <br />
                <br />
                이제 여기저기 검색은 그만
              </p>
              <a href="#" @click="handleClick" class="btn btn-primary me-2"
                >지금 바로 견적요청
                <arrow-right-icon class="icon-sm ms-1"></arrow-right-icon
              ></a>
            </div>
            <div class="col-lg-6 col-sm-10 mx-auto ms-lg-auto me-lg-0">
              <div class="mt-lg-0 mt-5">
                <img
                  src="@/assets/images/home.png"
                  alt="찾기 어려워요."
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Hero End -->
      <Services />
      <Procedure />
      <Caution />
      <Usages />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  </div>
</template>