<script>
export default {
  data() {
    return {
      teamData: [
        {
          profile: require("@/assets/images/procedure/001.png"),
          name: "사전 현장 확인",
          designation: "시공 전 짐이나 기존 문제있는 곳을 미리 체크합니다.",
        },
        {
          profile: require("@/assets/images/procedure/002.png"),
          name: "자재 확인 및 재단",
          designation: "요청자가 선택한 자재가 맞는지 확인 후 재단 시작",
        },
        {
          profile: require("@/assets/images/procedure/003.png"),
          name: "벽 상태 확인",
          designation: "벽 상태를 확인 후 필요시 기존 벽지 제거",
        },
        {
          profile: require("@/assets/images/procedure/004.png"),
          name: "초배지 작업",
          designation: "벽면에 초배지 작업 진행",
        },
        {
          profile: require("@/assets/images/procedure/005.png"),
          name: "벽지 부착",
          designation: "벽지를 정확한 위치에 부착",
        },
        {
          profile: require("@/assets/images/procedure/006.png"),
          name: "도배 후 정리",
          designation: "도배가 끝나고 깨끗하게 정리",
        },
      ],
    };
  },
};
</script>

<template>
  <!-- Team start -->
  <section class="section bg-light" id="procedure">
    <div class="container">
      <div class="row justify-content-center mb-4">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold">진행 과정</h2>
          <p class="text-muted">
            도배의 기본적인 진행 과정을 확인해주세요.
          </p>
        </div>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-sm-5"
          v-for="(item, index) of teamData"
          :key="index"
        >
          <div
            class="team-box mt-4 position-relative overflow-hidden rounded text-center shadow"
          >
            <div class="position-relative overflow-hidden">
              <img
                :src="item.profile"
                :alt="item.name"
                class="img-fluid d-block mx-auto"
              />
            </div>
            <div class="p-4">
              <h5 class="font-size-19 mb-1">{{ item.name }}</h5>
              <p class="text-muted text-uppercase font-size-14 mb-0">
                {{ item.designation }}
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Team end -->
</template>