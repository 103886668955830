<script>
export default {
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- Blog start -->
    <section class="section" id="usages">
      <div class="container">
        <div class="row justify-content-center mb-4">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">서비스 이용 방법</h2>
            <p class="text-muted">
              고쳐줘는 고객님들의 편의를 위해 따로 회원가입을 받지 않고 있습니다.
              <br/>
              카카오톡으로 간편하게 문의해 보세요.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                    src="@/assets/images/kakao_1.png"
                    class="w-100 me-3"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->

          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                      src="@/assets/images/kakao_2.png"
                      class="w-100 me-3"
                      alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->

          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                      src="@/assets/images/kakao_3.png"
                      class="w-100 me-3"
                      alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Blog end -->

  </div>
</template>